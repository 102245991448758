// result documentation
import ACADEMICCALENDAR from "../pdf/ResultsAcademics/ACADEMICCALENDAR.pdf";
import IQACMEETINGS from "../pdf/ResultsAcademics/IQAC MEETINGS.pdf";
import IQACMEETINGS19DEC2022 from "../pdf/ResultsAcademics/IQAC MEETING 19 DEC 2022.pdf";
import IQACMEETINGS10April2023 from "../pdf/ResultsAcademics/IQAC MEETING 10 APRIL 2023.pdf";
import StudentSatisfactionSurvey from "../pdf/ResultsAcademics/Student Satisfaction Survey (SSS).pdf";
import CommitteeMembers from "../pdf/ResultsAcademics/COMMITTEE MEMBERS.pdf";
import AQAR2017 from "../pdf/ResultsAcademics/AQAR Report 2017 - 2018.pdf";
import AQAR2018 from "../pdf/ResultsAcademics/AQAR report 2018 -2020.pdf";
import AQAR2019 from "../pdf/ResultsAcademics/AQAR report 2019 -2020.pdf";
import AQAR2020 from "../pdf/ResultsAcademics/AQAR Report 2020 - 2021.pdf";
import AQAR2021 from "../pdf/ResultsAcademics/AQAR Report 2021-2022.pdf";
import AQAR2022 from "../pdf/ResultsAcademics/AQAR 2022 -23 (1).pdf";
import MOU from "../pdf/ResultsAcademics/MOU.PDF";
import SSS2123 from "../pdf/ResultsAcademics/SSS 21-23.pdf";
import SSS1719 from "../pdf/ResultsAcademics/SSS 2017-19.pdf";
import SSS2224 from "../pdf/ResultsAcademics/SSS 2022-24.pdf"
import Activity from "../pdf/new/Activity 2024_240109_101610.pdf";
import AnnualReport from "../pdf/new/Annual ReportPDF_240109_101906.pdf";
import ActiontakenReport from "../pdf/new/IQAC (action taken report)_240109_101831.pdf";
import MembersAndminutes from "../pdf/new/IQAC Meeting, minutes with Report.pdf";
import MOUPDF from "../pdf/new/MOUPDF_240109_101649.pdf";
import SEMESTERWISECALENDER from "../pdf/new/SEMESTER WISE CALENDER 21 -23 and 22-24_240109_101715.pdf";
import RTI from "../pdf/new/RTI declaration Under Section 4 (1)(b).PDF";
import rag from "../pdf/new/Anti Ragging_page-0001.pdf";
import NCTE2005 from "../pdf/ncte/NCTE Order 2005.pdf";
import NCTE2015 from "../pdf/ncte/NCTE ORDERS.pdf";
import affilation from "../pdf/ncte/3 AFFILIATION ORDERS.pdf";
import land from "../pdf/ncte/4 CERTIFIED COPY OF LAND DOCUMENTS.pdf";
import landuse from "../pdf/ncte/5 LAND USE CERTIFICATE.pdf";
import buildingplan from "../pdf/ncte/6 BUILDING PLAN APPROVAL & COMPLETION CERTIFICATE.pdf";
import non from "../pdf/ncte/7 NON-ENCUMBRANCE CERTIFICATE.JPG";
import buildingsafety from "../pdf/ncte/8 BUILDING SAFETY & DIFFERENTLY ABLED FRIENDLY CERTIFICATE.JPG";
import firesafety from "../pdf/ncte/9 FIRE SAFETY CERTIFICATE.pdf";
import naacc from "../pdf/ncte/10 NAAC CERTIFICATE.jpg";
import affidavit from "../pdf/ncte/11 AFFIDAVIT.pdf";
import library from "../pdf/ncte/12 LIBRARY.pdf";
import staff from "../pdf/ncte/13 STAFF DETAIL.pdf";
import fee from "../pdf/ncte/14 FEE STRUCTURE.pdf";
import fdr from "../pdf/ncte/15 JOINT FDR.pdf";
import sheet from "../pdf/ncte/16 BALANCE SHEET.jpg";
import income from "../pdf/ncte/17 INCOME & EXPENDITURE DETAILS.jpeg";
import receipt from "../pdf/ncte/18 RECEIPT AND PAYMENT ACCOUNT FOR THE FINANCIAL YEAR.pdf";
import madatory from "../pdf/ncte/19 Mandatory-Disclosure.docx";
import recordInterns from "../pdf/new/RECORD INTERNS IN DIFFERENT STATES.pdf"
export const ResultsAcademicsData = [
  {
    id: 1,
    title: "ANNUAL ACADEMIC CALENDAR",
    document: ACADEMICCALENDAR,
  },
  {
    id: 2,
    title: "LIST OF IQAC MEETINGS",
    document: IQACMEETINGS,
  },
  {
    id: 3,
    title: "LIST OF COMMITTEE MEMBERS",
    document: CommitteeMembers,
  },
  {
    id: 4,
    title: "COPY OF STUDENT SATISFACTION SURVEY (SSS)",
    document: StudentSatisfactionSurvey,
  },
  {
    id: 5,
    title: "AQAR REPORT 2017 - 2018",
    document: AQAR2017,
  },
  {
    id: 6,
    title: "AQAR REPORT 2018 - 2019",
    document: AQAR2018,
  },
  {
    id: 7,
    title: "AQAR REPORT 2019 - 2020",
    document: AQAR2019,
  },
  {
    id: 8,
    title: "AQAR REPORT 2020 - 2021",
    document: AQAR2020,
  },
  {
    id: 9,
    title: "AQAR REPORT 2021 - 2022",
    document: AQAR2021,
  },
  {
    id: 10,
    title: "AQAR REPORT 2022 - 2023",
    document: AQAR2022,
  },
  {
    id: 11,
    title: "MEMORANDUM OF UNDERSTANDING",
    document: MOU,
  },
  {
    id: 12,
    title: "IQAC MEETING 19 DEC 2022",
    document: IQACMEETINGS19DEC2022,
  },
  {
    id: 13,
    title: "IQAC MEETING 10 APRIL 2023",
    document: IQACMEETINGS10April2023,
  },
  {
    id: 14,
    title: "Activity 2024",
    document: Activity,
  },
  {
    id: 15,
    title: "Annual Report",
    document: AnnualReport,
  },
  {
    id: 16,
    title: "IQAC (action taken report)",
    document: ActiontakenReport,
  },
  {
    id: 17,
    title: "IQAC members and minutes of meetings",
    document: MembersAndminutes,
  },
  // {
  //     id: 17,
  //     title: "MEMORANDUM OF UNDERSTANDING MOU NEW",
  //     document: MOUPDF,
  // },
  {
    id: 18,
    title: "SEMESTER WISE CALENDER 21 -23 and 22-24",
    document: SEMESTERWISECALENDER,
  },
  {
    id: 19,
    title: "Anti Ragging",
    document: rag,
  },
  {
    id: 20,
    title: "RTI declaration Under Section 4",
    document: RTI,
  },
  {
    id: 21,
    title: "Student Satisfaction Survey 21-23",
    document: SSS2123,

  },
  {
    id: 22,
    title: "Student Satisfaction Survey 17-19",
    document: SSS1719,
  },
  {
    id: 23,
    title: "Student Satisfaction Survey 22-24",
    document: SSS2224,
  },
  {
    id: 24,
    title: "Record Interns in different states",
    document: recordInterns,
  }
];


export const NcteDocument = [
  {
    id: 1,
    title: "NCTE ORDERS 2005",
    document: NCTE2005,
  },
  {
    id: 2,
    title: "NCTE ORDERS 2015",
    document: NCTE2015,
  },
  {
    id: 3,
    title: "AFFILIATION ORDERS",
    document: affilation,
  },
  {
    id: 4,
    title: "CERTIFIED COPY OF LAND DOCUMENTS",
    document: land,
  },
  {
    id: 5,
    title: "LAND USE CERTIFICATE",
    document: landuse,
  },
  {
    id: 6,
    title: "BUILDING PLAN APPROVAL & COMPLETION CERTIFICATE",
    document: buildingplan,
  },
  {
    id: 7,
    title: "NON-ENCUMBRANCE CERTIFICATE",
    document: non,
  },
  {
    id: 8,
    title: "BUILDING SAFETY & DIFFERENTLY ABLED FRIENDLY CERTIFICATE",
    document: buildingsafety,
  },
  {
    id: 9,
    title: "FIRE SAFETY CERTIFICATE",
    document: firesafety,
  },
  {
    id: 10,
    title: "NAAC CERTIFICATE",
    document: naacc,
  },
  {
    id: 11,
    title: "AFFIDAVIT",
    document: affidavit,
  },
  {
    id: 12,
    title: "LIBRARY",
    document: library,
  },
  {
    id: 13,
    title: "STAFF DETAIL",
    document: staff,
  },
  {
    id: 14,
    title: "FEE STRUCTURE",
    document: fee,
  },
  {
    id: 15,
    title: "JOINT FDR",
    document: fdr,
  },
  {
    id: 16,
    title: "BALANCE SHEET",
    document: sheet,
  },
  {
    id: 17,
    title: "INCOME & EXPENDITURE DETAILS",
    document: income,
  },
  {
    id: 18,
    title: "RECEIPT AND PAYMENT ACCOUNT FOR THE FINANCIAL YEAR",
    document: receipt,
  },
  {
    id: 19,
    title: "Mandatory-Disclosure",
    document: madatory,
  },
];
